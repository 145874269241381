import React from 'react'
import Logo from '../images/logo.svg'
import { Link } from 'gatsby'

const Footer = () => {
    return (
        <section className="py-20 bg-white">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center -mx-4">
                    <div className="w-full  px-4">
                        <Link id='logo' className="inline-block mb-6 text-gray-900 text-lg font-semibold w-full" to="/">
                            <img className="h-7 justify-center mx-auto " src={Logo} alt="macom planning co., ltd logo" width="auto" />
                        </Link>
                    </div>
                    <div className="w-full  px-4">
                        <ul className="flex flex-wrap items-center justify-center gap-6">
                            <li><Link id='companyProfile' className="text-sm font-light hover:border-b-8 border-orange-300 duration-500" to="/#company">会社概要</Link></li>
                            <li><Link id='productionDepartment' className="text-sm font-light hover:border-b-8 border-orange-300 duration-500" to="/production">制作事業</Link></li>
                            <li><Link id='welfareDepartment' className="text-sm font-light hover:border-b-8 border-orange-300 duration-500" to="/welfare">福祉事業</Link></li>
                            <li><Link id='inquiry' className="bg-gradient-to-br from-orange-400 to-pink-300  duration-500 text-white rounded-full py-2 px-4 font-light hover:font-extrabold" to="/#enquiry">お問い合わせ</Link></li>
                        </ul>
                        <p className="mt-6 text-center  font-light text-sm cursor-pointer"><Link id='policy' to="/policy" className='mx-auto hover:border-b-8 border-gray-300 duration-500'>情報セキュリティ基本方針について</Link></p>
                        <p className="mt-6 text-gray-500 text-center mx-auto font-light">©macom planning co., ltd. {new Date().getFullYear()} All rights reserved.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
