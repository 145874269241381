import React, { useState, useEffect } from 'react'
import Logo from '../images/logo.svg'
import { Link } from 'gatsby'
import { IoMenuOutline } from 'react-icons/io5'

const Navbar = () => {
    const [departed, setDeparted] = useState(false);
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setDeparted(true);
            } else {
                setDeparted(false);
            }
        });
    }, []);

    return (
        <>
            <nav className="w-full fixed z-50">
                <div className={`flex justify-between px-4 md:px-10 pt-6 pb-2  ${departed && 'shadow-md -translate-y-4 bg-white/75 md:my-1'}  duration-1000`}>
                    <Link id='logo' className="hover:border-b-8 border-orange-300 duration-500  mr-2 my-auto" to="/">
                        <img className="h-6" src={Logo} alt="マコムプラニング株式会社ロゴ" width="auto" />
                    </Link>
                    <IoMenuOutline onClick={() => setShow(!show)} className={`md:hidden h-7 w-7 rounded-full bg-gradient-to-br from-orange-300 to-pink-400 text-white p-1 cursor-pointer hover:animate-spin`} />
                    <ul className={!show ? 'hidden md:flex flex-between gap-x-6' : 'absolute right-0 top-14 p-4 text-center grid gap-y-4 shadow-md bg-white/90'}>
                        <li><Link id='companyProfile' className="text-sm font-light hover:font-extrabold hover:border-b-8 border-orange-300 duration-500" to="/#company">会社概要</Link></li>
                        <li><Link id='productionDepartment' className="text-sm font-light hover:font-extrabold hover:border-b-8 border-orange-300 duration-500" to="/production">制作事業</Link></li>
                        <li><Link id='welfareDepartment' className="text-sm font-light hover:font-extrabold hover:border-b-8 border-orange-300 duration-500" to="/welfare">福祉事業</Link></li>
                        <li><a id='staffRecruit'target="_blank" className="text-sm font-light hover:font-extrabold hover:border-b-8 border-orange-300 duration-500" href="https://recruit-macom-plan.com">スタッフ採用 </a></li>
                        <li><Link id='inquiry' className="bg-gradient-to-br from-orange-400 to-pink-300  duration-500 text-white rounded-full py-2 px-4 font-light hover:font-extrabold" to="/#enquiry">お問い合わせ</Link></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
